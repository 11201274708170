<template>
    <div class="container-fluid">
        <div class="c-sidebar-tiny">
            <div class="c-sidebar-tiny__inner">
                <ul>
                    <router-link to="/setting" v-slot="{href, isActive}">
                        <li :class="[isActive ? 'active' : '']">
                            <a :href="href">
                                <i class="fa fa-cogs"></i>
                                Cài đặt
                            </a>
                        </li>
                    </router-link>
                </ul>
            </div>
        </div>
        <main class="c-app-content is-sidebar-tiny">
            <div class=""> <!-- c-container-inner -->
                <div class="row">
                    <div class="col-12" style="justify-content: space-around; flex-direction: row; display: flex;">
                        <div class="c-btn-mobile js-btn-mobile mt-2"><i class="fa fa-bars"></i> Mở rộng menu</div>
                    </div>
                    <div class="col-lg-2 c-sidebar-main">
                        <div class="c-sidebar-menu">
                            <ul>
                                <li>
                                    <label>Thông tin tài khoản</label>
                                    <ul>
                                        <router-link to="/setting/profile" v-slot="{href, isExactActive}">
                                            <li :class="[isExactActive ? 'active' : '']"><a :href="href"><i class="fa fa-angle-right"></i> Thông tin tài khoản</a></li>
                                        </router-link>
                                        <router-link to="/setting/device-token" v-slot="{href, isExactActive}">
                                            <li   :class="[isExactActive ? 'active' : '']"><a :href="href"><i class="fa fa-angle-right"></i> Thiết bị đăng nhập</a></li>
                                        </router-link>
                                    </ul>
                                </li>
                                <li>
                                    <label>Quyền và tài khoản</label>
                                    <ul>
                                        <router-link to="/setting/client" v-slot="{href, isExactActive}">
                                            <li v-if="permissions('client_index')" :class="[isExactActive ? 'active' : '']"><a :href="href"><i class="fa fa-angle-right"></i> Đơn vị</a></li>
                                        </router-link>
                                        <router-link to="/setting/staff" v-slot="{href, isExactActive}">
                                            <li v-if="permissions('staff_index')" :class="[isExactActive ? 'active' : '']"><a :href="href"><i class="fa fa-angle-right"></i> Nhân viên</a></li>
                                        </router-link>
                                        <router-link to="/setting/roles" v-slot="{href, isExactActive}">
                                            <li v-if="permissions('auth-group_index')" :class="[isExactActive ? 'active' : '']"><a :href="href"><i class="fa fa-angle-right"></i> Nhóm quyền</a></li>
                                        </router-link>
                                        <router-link to="/setting/action-define" v-slot="{href, isExactActive}">
                                            <li v-if="permissions('auth-item-group_index','auth-item_index')" :class="[isExactActive ? 'active' : '']"><a :href="href"><i class="fa fa-angle-right"></i> Định nghĩa hành động</a></li>
                                        </router-link>
                                    </ul>
                                </li>
                                <li>
                                    <label>Cấu hình</label>
                                    <ul>
                                        <router-link to="/setting/etc" v-slot="{href, isExactActive}">
                                            <li v-if="permissions('etc_index')" :class="[isExactActive ? 'active' : '']"><a :href="href"><i class="fa fa-angle-right"></i> Thông tin ứng dụng</a></li>
                                        </router-link>
                                        <router-link  to="/setting/email" v-slot="{href, isExactActive}">
                                            <li v-if="permissions('user-email_index')"  :class="[isExactActive ? 'active' : '']"><a :href="href"><i class="fa fa-angle-right"></i> Email gửi đi</a></li>
                                        </router-link>
                                        <router-link to="/setting/dashboard" v-slot="{href, isExactActive}">
                                            <li v-if="permissions('etc_index')" :class="[isExactActive ? 'active' : '']"><a :href="href"><i class="fa fa-angle-right"></i> Giao diện Dashboard</a></li>
                                        </router-link>
                                    </ul>
                                </li>
                                <li v-for="group in groups" :key="group">
                                    <label>{{group}}</label>
                                    <ul>
                                        <router-link v-for="field in $params.const" :key="field.key" :to="`/setting/parameter/${field.key}`" v-slot="{href, isExactActive}">
                                            <li v-if="group === field.group" :class="[isExactActive ? 'active' : '']">
                                                <a :href="href">
                                                    <i class="fa fa-angle-right"></i>
                                                    {{field.label}}
                                                </a>
                                            </li>
                                        </router-link>
                                    </ul>
                                </li>
                                <li>
                                    <label>Lịch sử</label>
                                    <ul>
                                        <!-- <router-link to="/setting/staff-activity" v-slot="{href, isExactActive}">
                                            <li v-if="permissions('staff-activity_index')" :class="[isExactActive ? 'active' : '']"><a :href="href"><i class="fa fa-angle-right"></i> Lịch sử truy cập</a></li>
                                        </router-link> -->
                                        <router-link to="/setting/emailoutbox" v-slot="{href, isExactActive}">
                                            <li v-if="permissions('setting_emailoutbox')" :class="[isExactActive ? 'active' : '']"><a :href="href"><i class="fa fa-angle-right"></i> Lịch sử gửi email</a></li>
                                        </router-link>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-10">
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import $ from 'jquery';
    import role from '@/mixins/Role';
    export default {
        mixins: [role],
        data: function () {
            return {
                groups: [],
                config: []
            };
        },
        mounted: function () {
            this.config = this.$store.state.etc && this.$store.state.etc.site ? this.$store.state.etc.site : [];
            this.groups = [];
            Object.keys(this.$params.const).forEach(key => {
                let param = this.$params.const[key];
                if (this.config && ((!this.config.includes('svf') && param.group === 'SVF Platform') || (!this.config.includes('sog') && param.group === 'SOG Platform'))) {
                    return false;
                }
                if (this.groups.indexOf(param.group) === -1) {
                    this.groups.push(param.group);
                }
            });

            //js expand menu setting
            $('.js-btn-mobile').on('click', function (e) {
                e.preventDefault();
                var sidebar = $('.c-sidebar-main');
                if (sidebar.hasClass('active')) {
                    sidebar.removeClass('active').slideUp();
                } else {
                    sidebar.addClass('active').slideDown();
                }
            });

            //close setting menu in mobile
            $('.c-sidebar-menu a').on('click', function (e) {
                var sidebar = $('.c-sidebar-main');
                var viewportGlobal = $(window).width();
                if (e.target == this && viewportGlobal < 768) {
                    sidebar.removeClass('active').slideUp();
                }
            });
        }
    }
</script>